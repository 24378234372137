import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {useIntl, Link, FormattedMessage, navigate} from "gatsby-plugin-intl"

const Terms = () =>  {
    const intl = useIntl();

    return (
    <Layout>
      <SEO title="Terms of use" />

      <div className="white-container" id="legals">

        <h1 className="text-center dark-grey text-uppercase mb-5">{intl.formatMessage({ id: "terms.title" })}</h1>

        <p>{intl.formatMessage({ id: "terms.date" })}</p>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.warning" })}} />

        <h2>{intl.formatMessage({ id: "terms.title1" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text1" })}} />

        <h2>{intl.formatMessage({ id: "terms.title2" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text2" })}} />

        <h2>{intl.formatMessage({ id: "terms.title3" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text3" })}} />

        <h2>{intl.formatMessage({ id: "terms.title4" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text4" })}} />

        <h2>{intl.formatMessage({ id: "terms.title5" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text5" })}} />

        <h2>{intl.formatMessage({ id: "terms.title6" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text6" })}} />

        <h2>{intl.formatMessage({ id: "terms.title7" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text7" })}} />

        <h2>{intl.formatMessage({ id: "terms.title8" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text8" })}} />

        <h2>{intl.formatMessage({ id: "terms.title9" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text9" })}} />

        <h2>{intl.formatMessage({ id: "terms.title10" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text10" })}} />

        <h2>{intl.formatMessage({ id: "terms.title11" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text11" })}} />

        <h2>{intl.formatMessage({ id: "terms.title12" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "terms.text12" })}} />

      </div>

    </Layout>
    )
}

export default Terms
